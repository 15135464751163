@import "colors";
@import "theme";

$ksi-semibold: 410;

$ksi-padding: 16px;
$ksi-padding-small: 8px;
$ksi-padding-x-small: 4px;
$ksi-padding-large: 32px;

$ksi-margin: 16px;
$ksi-margin-small: 8px;
$ksi-margin-x-small: 4px;
$ksi-margin-large: 32px;
$ksi-margin-x-large: 32px;

// should be kept in-sync with SIZE_MOBILE and SIZE_MOBILE_SMALL inside WindowService
$ksi-max-mobile-width: 900px;
$ksi-max-small-mobile-width: 850px;
