@import "colors";

//noinspection DuplicatedCode
body {
  --ksi-page-bg: #{$ksi-orange-240};
  --ksi-page-fg: #{$ksi-gray-30};

  --ksi-modal-bg: #{$ksi-white};

  --ksi-navbar-bg: #{$ksi-blue-30};
  --ksi-navbar-fg: #{$ksi-white};

  --ksi-page-welcome-banner-bg: #{$ksi-orange-140};
  --ksi-header-fg: #{$ksi-blue-20};

  --ksi-footer-link-fg: #{$ksi-blue-190};
  --ksi-footer-link-fg-hover: #{$ksi-blue-210};

  --ksi-article-preview-bg: #{$ksi-orange-230};
  --ksi-article-preview-border-fg: #{$ksi-orange-190};

  --ksi-code-bg: #{$ksi-white};
  --ksi-code-fg: #{$ksi-gray-30};
  --ksi-code-string-fg: #{$ksi-blue-50};

  --ksi-title: #{$ksi-gray-30};

  --ksi-input-focus-fg: #{$ksi-black};
  --ksi-input-focus-bg: #{$ksi-white};

  --ksi-thumbnail-bg: #{$ksi-orange-230};
  --ksi-thumbnail-hover-bg: #{$ksi-orange-240};

  --ksi-dropdown-hover-bg: #{$ksi-orange-200};

  --ksi-successful-result-fg-1: #{$ksi-orange-230};
  --ksi-successful-result-fg-2: #{$ksi-orange-220};

  --ksi-code-builtin: #{$ksi-code-builtin-dark};
  --ksi-code-number: #{$ksi-code-number-dark};
  --ksi-code-keyword: #{$ksi-code-keyword-dark};
  --ksi-code-def: #{$ksi-code-def-dark};
  --ksi-code-comment: #{$ksi-code-comment-dark};
  --ksi-code-string: #{$ksi-code-string-dark};
  --ksi-code-variable-2: #{$ksi-code-variable-2-dark};
  --ksi-code-meta: #{$ksi-code-meta-dark};

  &.theme-dark {
    --ksi-page-bg: #{$ksi-gray-20};
    --ksi-page-fg: #{$ksi-gray-220};

    --ksi-modal-bg: #{$ksi-gray-20};

    --ksi-page-welcome-banner-bg: #{$ksi-orange-90};
    --ksi-header-fg: #{$ksi-blue-160};

    --ksi-article-preview-bg: #{$ksi-gray-20};
    --ksi-article-preview-border-fg: #{$ksi-blue-90};

    --ksi-code-bg: #{$ksi-gray-30};
    --ksi-code-fg: #{$ksi-gray-220};
    --ksi-code-string-fg: #{$ksi-blue-120};

    --ksi-title: #{$ksi-gray-220};

    --ksi-input-focus-fg: #{$ksi-white};
    --ksi-input-focus-bg: #{$ksi-gray-30};

    --ksi-thumbnail-bg: #{$ksi-gray-30};
    --ksi-thumbnail-hover-bg: #{$ksi-gray-20};

    --ksi-dropdown-hover-bg: #{$ksi-orange-40};

    --ksi-successful-result-fg-1: #{$ksi-orange-20};
    --ksi-successful-result-fg-2: #{$ksi-orange-30};

    --ksi-code-builtin: #{$ksi-code-builtin-light};
    --ksi-code-number: #{$ksi-code-number-light};
    --ksi-code-keyword: #{$ksi-code-keyword-light};
    --ksi-code-def: #{$ksi-code-def-light};
    --ksi-code-comment: #{$ksi-code-comment-light};
    --ksi-code-string: #{$ksi-code-string-light};
    --ksi-code-variable-2: #{$ksi-code-variable-2-light};
    --ksi-code-meta: #{$ksi-code-meta-light};
  }
}

// AUTO-GENERATED BY gen-scss-theme.sh on Fri Oct 28 21:59:07 CEST 2022
$ksi-article-preview-bg: var(--ksi-article-preview-bg);
$ksi-article-preview-border-fg: var(--ksi-article-preview-border-fg);
$ksi-code-bg: var(--ksi-code-bg);
$ksi-code-builtin: var(--ksi-code-builtin);
$ksi-code-comment: var(--ksi-code-comment);
$ksi-code-def: var(--ksi-code-def);
$ksi-code-fg: var(--ksi-code-fg);
$ksi-code-keyword: var(--ksi-code-keyword);
$ksi-code-meta: var(--ksi-code-meta);
$ksi-code-number: var(--ksi-code-number);
$ksi-code-string: var(--ksi-code-string);
$ksi-code-string-fg: var(--ksi-code-string-fg);
$ksi-code-variable-2: var(--ksi-code-variable-2);
$ksi-dropdown-hover-bg: var(--ksi-dropdown-hover-bg);
$ksi-footer-link-fg: var(--ksi-footer-link-fg);
$ksi-footer-link-fg-hover: var(--ksi-footer-link-fg-hover);
$ksi-header-fg: var(--ksi-header-fg);
$ksi-input-focus-bg: var(--ksi-input-focus-bg);
$ksi-input-focus-fg: var(--ksi-input-focus-fg);
$ksi-modal-bg: var(--ksi-modal-bg);
$ksi-navbar-bg: var(--ksi-navbar-bg);
$ksi-navbar-fg: var(--ksi-navbar-fg);
$ksi-page-bg: var(--ksi-page-bg);
$ksi-page-fg: var(--ksi-page-fg);
$ksi-page-welcome-banner-bg: var(--ksi-page-welcome-banner-bg);
$ksi-successful-result-fg-1: var(--ksi-successful-result-fg-1);
$ksi-successful-result-fg-2: var(--ksi-successful-result-fg-2);
$ksi-thumbnail-bg: var(--ksi-thumbnail-bg);
$ksi-thumbnail-hover-bg: var(--ksi-thumbnail-hover-bg);
$ksi-title: var(--ksi-title);
