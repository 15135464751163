/* You can add global styles to this file, and also import other style files */
@import "src/app/styles/theme";
@import "src/app/styles/vars";
@import "src/app/styles/colors";
@import "assets/font/opensans/opensans";
@import "../node_modules/highlight.js/styles/github.css";

body {
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
}

.form-control.ng-invalid:not(.ng-pristine) {
  border: 1px solid $ksi-red-100;
}

/* Importing Bootstrap SCSS file. */
$code-color: #C00058;
@import '~bootstrap/scss/bootstrap';

/* Importing Datepicker SCSS file. */
@import "~ngx-bootstrap/datepicker/bs-datepicker";

/**
Quill.js core style
 */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

/**
Codemirror core style
 */
@import "~codemirror/lib/codemirror.css";
.CodeMirror, .CodeMirror-gutters {
  color: $ksi-code-fg;
  background: $ksi-code-bg;
}

.code-editor {
  .cm-builtin {color: $ksi-code-builtin}
  .cm-number {color: $ksi-code-number}
  .cm-keyword {color: $ksi-code-keyword}
  .cm-def {color: $ksi-code-def}
  .cm-comment {color: $ksi-code-comment}
  .cm-string {color: $ksi-code-string}
  .cm-variable-2 {color: $ksi-code-variable-2}
  .cm-meta {color: $ksi-code-meta}
}

.sourceCode .python .hljs {
  .hljs-keyword {color: $ksi-code-keyword}
  .hljs-string {color: $ksi-code-string}
  .hljs-built_in {color: $ksi-code-builtin}
  .hljs-comment {color: $ksi-code-comment}
  .hljs-number {color: $ksi-code-number}
  .hljs-meta {color: $ksi-code-meta}
  .hljs-title {color: $ksi-code-def}
}

table {
  th, td {
    color: $ksi-page-fg;
  }
}

/**
Code highlighting
 */
.hljs {
  color: $ksi-code-fg;
  background: $ksi-code-bg;

  .hljs-meta .hljs-string, .hljs-regexp, .hljs-string{
    color: $ksi-code-string-fg;
  }
}

.panel.card {
  color: $ksi-page-fg;

  &, .card-header {
    background: $ksi-page-bg;
    border-color: $ksi-article-preview-border-fg;
  }

  .card-header {
    color: $ksi-title;
  }

  .accordion-toggle > button {
    color: $ksi-page-fg;

    &:hover, &:focus {
      border-color: transparent;
      text-decoration: none;
    }
  }
}

.modal-content {
  color: $ksi-page-fg;
  background: $ksi-modal-bg;
}

.form-control {
  color: $ksi-page-fg;
  background: $ksi-modal-bg;

  &:focus {
    color: $ksi-input-focus-fg;
    background: $ksi-input-focus-bg;
  }
}

.btn-ksi {
  color: $ksi-navbar-fg;
  padding: $ksi-padding-small;
  text-decoration: none;
  transition: background-color 0.1s;
  background-color: $ksi-page-welcome-banner-bg;
  border-radius: 0;

  &.active,&:hover:not([disabled]),&[aria-expanded=true] {
    background: $ksi-orange-160 !important;
  }
}

.clickable {
  cursor: pointer;
}

.area-code-output {
  width: 100%;
  min-height: 15em;
  font-family: monospace;
}

.modal-dialog {
  &.modal-full-page {
    max-width: min(95vw, 26cm);
  }

  &.modal-full-width {
    max-width: 98vw;
  }

  &.modal-full-height {
    height: calc(100vh - 2 * #{$ksi-padding-large});

    .modal-content {
      height: 100%;
    }
  }
}

blockquote {
  margin: 0;
  margin-left: $ksi-margin-small;
  padding-left: $ksi-padding-small;
  padding-bottom: $ksi-padding-x-small;
  border-left: 2px solid $ksi-gray-80;
}

.navigation-bar {
  background: $ksi-navbar-bg;
  color: $ksi-navbar-fg;

  .nav-btn {
    color: $ksi-navbar-fg;
    padding: $ksi-padding;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.1s;

    &.active,&:hover,&[aria-expanded=true] {
      background: $ksi-page-welcome-banner-bg !important;
    }
  }
}

.form-label {
  margin-bottom: 0;
  font-weight: bold;
  font-size: 0.9rem;
}

/* Make disabled collapsible panel appear non-clickable */
accordion-group > .panel > .panel-disabled {
  * {
    cursor: default !important;
  }
}
